import React from 'react'
import '../CSS/Components/Loading.css'

const Loading = () => {
    return (
        <div className='loader--screen'>
            <div className="box"><div className="loader"></div></div>
        </div>
    )
}

export default Loading