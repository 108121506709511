import React, { useEffect, useState } from 'react'
import '../CSS/Home.css'
import axios from 'axios'

import Bottom from '../Components/Bottom'
import { GiCheckMark } from "react-icons/gi";

import { HiOutlineSpeakerWave } from "react-icons/hi2";
import 'bear-react-carousel/dist/index.css';
import { RxCross2 } from "react-icons/rx";
import { FaCaretDown } from "react-icons/fa";

import BearCarousel, { TBearSlideItemDataList, BearSlideCard } from 'bear-react-carousel';
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import img1 from '../Images/Carousel/img1.jpg'
import img2 from '../Images/Carousel/img2.jpg'

import logo from '../Images/logo.png'
import Error from '../Components/Error';
import Loading from '../Components/Loading';

import longImg1 from '../Images/Home/img1.jpg'
import longImg2 from '../Images/Home/img2.jpg'

import icon1 from '../Images/Home/icon1.png'
import icon2 from '../Images/Home/icon2.png'
import icon3 from '../Images/Home/icon3.jpg'
import icon4 from '../Images/Home/icon4.jpg'

const Home = ({ token, rate, symbol }) => {
    const [name, setName] = useState('XXXXX XXX')
    const [phone, setPhone] = useState('+1 1234XXXX90')

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [popup, setPopup] = useState(false)

    const [plans, setPlans] = useState([])
    const [activeType, setActiveType] = useState(true)
    const [product, setProduct] = useState({})

    const [compound, setCompound] = useState(0)
    const [invested, setInvested] = useState(true)
    const [popup2, setPopup2] = useState(false)

    const currencyList = [{
        symbol: '₹',
        currency: 'INR',
        fullName: 'IN Rupee',
        rate: 1
    }, {
        symbol: '$',
        currency: 'USD',
        fullName: 'US Dollar',
        rate: 83
    }, {
        symbol: '€',
        currency: 'EUR',
        fullName: 'Euro',
        rate: 87
    }]

    const images = [
        { id: 1, img: img1 },
        { id: 2, img: img2 }
    ];

    const menu = [
        {
            item: 'Recharge',
            img: icon4,
            path: () => {
                window.location.href = '/#/recharge'
            }
        },
        {
            item: 'Withdraw',
            img: icon2,
            path: () => {
                window.location.href = '/#/withdraw'
            }
        },
        {
            item: 'My Team',
            img: icon1,
            path: () => {
                window.location.href = '/#/team'
            }
        },
        {
            item: 'Activity',
            img: icon3,
            path: () => {
                window.location.href = '/#/activity'
            }
        }
    ]

    const bearSlideItemData: TBearSlideItemDataList = images.map(row => {
        return {
            key: row.id,
            children: <BearSlideCard>
                <img src={row.img} width='100%' height={'100%'} alt="" />
            </BearSlideCard>
        };
    });

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/home`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let plans = data.plans;
                setPlans(plans);

                setName(data.name);
                setPhone(data.isd + ' ' + data.phone)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    async function updateCur(prop) {
        setLoader(true);

        let cid = prop === '₹' ? 'INR' : prop === '$' ? 'USD' : 'EUR'

        axios.get(`${process.env.REACT_APP_SERVER}/update/currency?currency=${cid}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                localStorage.setItem('rate', cid === 'INR' ? 1 : cid === 'EUR' ? 87 : 83);
                localStorage.setItem('symbol', prop);
                setPopup2(false);
                window.location.reload()
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    async function openPopup(index, ro) {
        setPopup(true);
        setProduct(plans[index])
        setActiveType(true)
        setCompound(ro)
    }


    async function onInvest() {
        setPopup(false)
        setLoader(true)

        axios.post(`${process.env.REACT_APP_SERVER}/invest`, { index: product.id - 1, method: activeType }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setLoader(false);

                setIsError(true)
                setErrorText('Invested Successfully')

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    //'optiwealthsupport1', 'optiweath', 
    const links = ['optiwealthsupport', 'optiwealthsupport1'];

    function openRandChat() {
        const randomIndex = Math.floor(Math.random() * links.length);
        const randomLink = links[randomIndex];

        window.open('tg://resolve?domain=' + randomLink)
    }

    return (
        <div className='home'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            {popup2 ? <div className='popup--screen'>
                <div className="popup--canceller" onClick={() => setPopup2(false)}></div>
                <div className="main">
                    <div className="popup--top">
                        <div className="left">Select Currency</div>
                        <div className="right">
                            <div className="icon" onClick={() => setPopup2(false)}>
                                <RxCross2 />
                            </div>
                        </div>
                    </div>

                    <div className="popup--body">
                        {currencyList.map((item, index) => {
                            return (
                                <div className="col" key={index} onClick={() => updateCur(item.symbol)}>
                                    <div className="left">
                                        <div className="code">{item.fullName}</div>
                                        <div className="country">({item.symbol})</div>
                                    </div>

                                    {item.symbol === localStorage.getItem("symbol") ? <div className="right">
                                        <IoCheckmarkDoneSharp />
                                    </div> : <></>}
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div> : <></>}

            {popup ? <div className='popup--screen'>
                <div className="popup--canceller" onClick={() => setPopup(!popup)}></div>
                <div className="main">
                    <div className="popup--top">
                        <div className="left">Invest</div>
                        <div className="right">
                            <div className="icon" onClick={() => setPopup(!popup)}>
                                <RxCross2 />
                            </div>
                        </div>
                    </div>

                    <div className="popup--body type2">
                        <div className="product--data">

                            <div className="tp--txt">Select investment method:</div>

                            <div className="inv--methods">
                                <div className={activeType ? "col2 active" : "col2"} onClick={() => setActiveType(true)}>
                                    <center>
                                        <div className="tp--txt2">Daily Return</div>
                                        <div className="bp--txt">Total Return {symbol}{parseFloat((((product.amount === 0 ? 12 : (product.amount * (product.daily / 100))) * product.period) / rate).toFixed(2))}</div>

                                        {activeType ? <div className="act--sign">
                                            <GiCheckMark style={{ marginTop: 15, marginLeft: 30, fontSize: 22, color: '#fff' }} />
                                        </div> : <></>}
                                    </center>
                                </div>
                                {product.methods === false ? <div className="col2">
                                    <div className="tp--txt2">Not Available</div>
                                </div> : <div className={activeType === false ? "col2 active" : "col2"} onClick={() => setActiveType(false)}>
                                    <center>
                                        <div className="tp--txt2">Compound Return</div>
                                        <div className="bp--txt">Total Return {symbol}{parseFloat((compound / rate).toFixed(2))}</div>

                                        {activeType === false ? <div className="act--sign">
                                            <GiCheckMark style={{ marginTop: 15, marginLeft: 30, fontSize: 22, color: '#fff' }} />
                                        </div> : <></>}
                                    </center>
                                </div>}
                            </div>

                            <div className="pd--box">
                                <div className="tp--col">
                                    <div className="lf">
                                        <div className="inv--name">{product.item}</div>
                                        <div className="inv--duration">--For {activeType ? product.period : product.period / 4} days</div>
                                    </div>
                                    <div className="lr">{symbol}{parseFloat((product.amount / rate).toFixed(2))}</div>
                                </div>

                                {activeType ? <div className="bp--col">
                                    <div className="lf">Daily / Total Return</div>
                                    <div className="rf">{symbol}{parseFloat((product.amount === 0 ? parseFloat((12 / rate).toFixed(2)) : ((product.amount * (product.daily / 100)) / rate)).toFixed(2))} / {symbol}{parseFloat((((product.amount === 0 ? 12 : (product.amount * (product.daily / 100)) / rate)) * product.period).toFixed(2))}</div>
                                </div> : <div className="bp--col">
                                    <div className="lf">Total Return</div>
                                    <div className="rf">{symbol}{parseFloat((compound / rate).toFixed(2))}</div>
                                </div>}

                                {activeType === false ? <div className="imp--txt">* On compound return method, you will receive your complete income after the period gets completed</div> : <></>}
                            </div>

                            <button className="confirm--invest" onClick={onInvest}>Confirm Investment</button>
                        </div>
                    </div>
                </div>
            </div> : <></>}

            <div className="top--bar">
                <div className="left">
                    <div className="logo">
                        <img src={logo} height={'55px'} alt="" />
                    </div>

                    <div className="left--right">
                        <div className="lr--top">{name}</div>
                        <div className="lr--bottom">{phone}</div>
                    </div>
                </div>
                <div className="right">
                    <div className="currencyChanger" onClick={() => setPopup2(true)}>
                        <div className="lec--left">{currencyList.filter(x => x.symbol === localStorage.getItem("symbol"))[0].currency}</div>
                        <div className="lec--right">
                            <FaCaretDown />
                        </div>
                    </div>
                </div>
            </div>

            <div className="carousel">
                <div className="box">
                    <BearCarousel
                        data={bearSlideItemData}
                        height={'100%'}
                        isEnableNavButton={false}
                        isEnablePagination={true}
                        isDebug={false}
                    />
                </div>
            </div>

            <div className="announcement">
                <div className="box">
                    <div className="left">
                        <HiOutlineSpeakerWave />
                    </div>
                    <div className="right">
                        <marquee>Welcome to OptiWealth, your premier destination for smart investments and financial prosperity. Choose from our diverse range of investment plans tailored to your goals, earning daily returns and unlocking the potential of compounding. Our user-friendly platform allows flexible deposits and withdrawals, ensuring you have control over your finances anytime, anywhere.</marquee>
                    </div>
                </div>
            </div>

            <div className="menu">
                {menu.map((item, index) => {
                    return (
                        <div className='col' key={index} onClick={item.path}>
                            <center>
                                <div className="icon">
                                    <img src={item.img} width={'100%'} height={'100%'} alt="" />
                                </div>
                                <div className="name">{item.item}</div>
                            </center>
                        </div>
                    )
                })}
            </div>

            <div className="menu--2">
                <div className="col">
                    <img src={longImg2} width={'100%'} height={'100%'} alt="" onClick={() => window.open('tg://resolve?domain=opti_globals')} />
                </div>
                <div className="col">
                    <img src={longImg1} width={'100%'} height={'100%'} alt="" onClick={() => window.open('tg://resolve?domain=opti_global')} />
                </div>
            </div>

            <br />

            <div className="plans">
                {plans.map((item, index) => {
                    let ro = item.amount;

                    let period = item.period / 4

                    for (let i = 0; i < period; i++) {
                        ro += ro * (item.daily / 100)
                    }

                    let dr = item.amount === 0 ? parseFloat((12 / rate).toFixed(2)) : parseFloat(((item.amount * (item.daily / 100)) / rate).toFixed(2))

                    return (
                        <div className="col" key={index}>
                            <div className="col--top">
                                <div className="left">
                                    <div className="plan--name">{item.item}</div>
                                </div>
                                <button onClick={() => openPopup(index, ro)}>Invest</button>
                            </div>
                            <div className="breaker">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>

                            <div className="col--bottom"> <br />
                                <div className="new--col">
                                    <div className="col--left">Investment Amount</div>
                                    <div className="col--right">{symbol}{parseFloat((item.amount / rate).toFixed(2))}</div>
                                </div>
                                <div className="new--col">
                                    <div className="col--left">Investment Duration</div>
                                    <div className="col--right">{item.period} days</div>
                                </div>
                                <div className="new--col">
                                    <div className="col--left">Daily Return / Total Profit</div>
                                    <div className="col--right">{symbol}{dr} / {symbol}{parseFloat((dr * item.period).toFixed(2))}</div>
                                </div>
                                {item.methods === true ? <div className="new--col">
                                    <div className="col--left">On Compound Invest</div>
                                    <div className="col--right">{symbol}{parseFloat((ro / rate).toFixed(2))} for {period}days</div>
                                </div> : <></>}
                            </div>
                        </div>
                    )
                })}
            </div>


            <Bottom page='Home' />
        </div>
    )
}

export default Home