import React, { useState, useEffect } from 'react'
import '../CSS/Recharge.css'

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

import Error from '../Components/Error';
import Loading from '../Components/Loading';

import axios from 'axios'

const Recharge = ({ token, rate, symbol }) => {
    const navigate = useNavigate()

    const arr = [600, 1500, 3400, 8700, 19500, 40000]
    const methods = ['INR', 'TRX', 'USDT']

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [balance, setBalance] = useState(0)
    const [amount, setAmount] = useState('')
    const [type, setType] = useState(methods[0])

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/recharge`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setBalance(data.balance)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    async function onRecharge() {
        let minRec = parseFloat((600 / rate).toFixed(2))
        if (amount < minRec) {
            setIsError(true)
            setErrorText(`Minimum amount to recharge is ${symbol}${minRec}`)

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        let newAmount = amount * rate

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/recharge/create/order`, { amount: newAmount, method: type }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data
                window.location.href = data.url;

                const timerId = setTimeout(() => {
                    setLoader(false)
                }, 3000);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='recharge'>
        {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">
                <div className="top">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <GoArrowLeft />
                    </div>
                    <div className="text">Recharge</div>
                </div>

                <div className="bottom">
                    <div className="balance">
                        <div className="icon">{symbol}</div>
                        <div className="amount">{parseFloat((balance / rate).toFixed(2))}</div>
                    </div>
                    <div className="txt">My Balance</div>
                </div>
            </div>

            <div className="section">
                <div className="col">
                    <div className="head">
                        <div className='text'>Deposit amount</div>
                        <div className="border"></div>
                    </div>

                    <div className="input">
                        <div className="icon">{symbol}</div>
                        <input type="number" placeholder={`${parseFloat((500 / rate).toFixed(2))} - ${parseFloat((40000 / rate).toFixed(2))}`} value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                </div>

                <div className="col">
                    <div className="head">
                        <div className='text'>Quick amount</div>
                        <div className="border"></div>
                    </div>

                    <div className="options">
                        {arr.map((item, index) => {
                            return (
                                <div key={index} className={parseFloat((item / rate).toFixed(2)) === amount ? 'box active' : 'box'} onClick={() => setAmount(parseFloat((item / rate).toFixed(2)))}>
                                    <div className="icon">{symbol}</div>
                                    <div className="amount">{parseFloat((item / rate).toFixed(2))}</div>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className="col">
                    <div className="head">
                        <div className='text'>Deposit method</div>
                        <div className="border"></div>
                    </div>

                    <div className="methods">
                        {methods.map((item, index) => {
                            return (
                                <div className={item === type ? 'box active' : 'box'} key={index} onClick={() => setType(item)}>{item}</div>
                            )
                        })}
                    </div>
                </div>

                <center>
                    <button className="deposit" onClick={onRecharge}>Recharge</button>
                </center>
            </div>
        </div>
    )
}

export default Recharge