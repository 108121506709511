import React, { useState, useEffect } from 'react'
import '../CSS/Withdraw.css'

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { IoMdAdd } from "react-icons/io";
import { GiCheckMark } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";

import Error from '../Components/Error';
import Loading from '../Components/Loading';

import axios from 'axios'

const Withdraw = ({ token, rate, symbol }) => {
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);
    const [popup, setPopup] = useState(false);

    const [popupLoader, setPopupLoader] = useState(false)
    const [popupError, setPopupError] = useState(false)
    const [popupErrorText, setPopupErrorText] = useState('')

    const [balance, setBalance] = useState(0);
    const [wallet, setWallet] = useState([]);
    
    const [type, setType] = useState('INR')
    const [selected, setSelected] = useState(0)
    const [amount, setAmount] = useState('')

    const [holder, setHolder] = useState('')
    const [number, setNumber] = useState('')
    const [IFSC, setIFSC] = useState('')

    const [address, setAddress] = useState('')

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/withdraw`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setBalance(data.balance);
                setWallet(data.wallets.reverse())

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    async function addWallet() {
        if (type === 'INR') {
            if (!holder) {
                setPopupError(true)
                setPopupErrorText('Please enter account holder name')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            } else if (!number) {
                setPopupError(true)
                setPopupErrorText('Please enter bank account number')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            } else if (number.length < 9 || isNaN(number)) {
                setPopupError(true)
                setPopupErrorText('Please enter valid account number')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            } else if (!IFSC) {
                setPopupError(true)
                setPopupErrorText('Please enter bank IFSC Code')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            } else if (IFSC.length !== 11 || parseFloat(IFSC.toString()[4]) !== 0 || isNaN(IFSC.slice(-7))) {
                setPopupError(true)
                setPopupErrorText('Please enter valid IFSC')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }
        } else {
            if (!address) {
                setPopupError(true)
                setPopupErrorText('Please enter your trc20 address')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            } else if (address.length !== 34) {
                setPopupError(true)
                setPopupErrorText('Please enter valid address')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }
        }

        setPopupLoader(true)

        let dataToSend = type === 'INR' ? {
            holder, number, IFSC, type
        } : {
            type, address
        }

        axios.post(`${process.env.REACT_APP_SERVER}/withdraw/add/wallet`, dataToSend, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                let wallets = data.wallets;
                setWallet(wallets.reverse())

                setPopupLoader(false)
                setPopupError(true)
                setPopupErrorText('Wallet added successfully')

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')

                    setPopup(false)
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setPopupLoader(false)
                setPopupError(true)
                setPopupErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setPopupError(false)
                    setPopupErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }


    async function onWithdraw() {
        if (wallet.length === 0) {
            setIsError(true)
            setErrorText('Please add your wallet to withdraw')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (selected === 0 || !wallet[selected - 1].methodId) {
            setIsError(true)
            setErrorText('Please select the wallet to withdraw')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        let min = parseFloat((200 / rate).toFixed(2));
        if (amount < min) {
            setIsError(true)
            setErrorText('Minimum amount to withdraw is ' + symbol + ' ' + min)

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/withdraw/request`, {
            methodId: wallet[selected - 1].methodId, amount: amount * rate
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(response => {
            let data = response.data;
            setBalance(data.balance)

            setLoader(false)
            setIsError(true)
            setErrorText('Withdrawal Successful')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }).catch(error => {
            setLoader(false)
            setIsError(true)
            setErrorText(error.response.data.message)

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        })
    }

    return (
        <div className='withdraw'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            {popup ? <div className='popup--screen'>
                <div className="popup--canceller" onClick={() => setPopup(!popup)}></div>
                <div className="main">
                    {popupError ? <div className="popup--s">
                        <div className="popup--error">{popupErrorText}</div>
                    </div> : <></>}

                    {popupLoader ? <div className="popup--s">
                        <div className="popup--loader"></div>
                    </div> : <></>}

                    <div className="popup--top">
                        <div className="left">Add Wallet</div>
                        <div className="right">
                            <div className="icon" onClick={() => setPopup(!popup)}>
                                <RxCross2 />
                            </div>
                        </div>
                    </div>

                    <div className="popup--body type2">
                        <div className="withdraw--details">
                            <div className="tp--txt">Select wallet type:</div>

                            <div className="withdraw--wallets">
                                <div className={type === 'INR' ? "col3 active" : "col3"} onClick={() => {
                                    if (type === 'INR') return;

                                    setType('INR')
                                }}>
                                    <div className="left">
                                        <div className="inner"></div>
                                    </div>
                                    <div className="right">INR </div>
                                </div>
                                <div className={type === 'TRX' ? "col3 active" : "col3"} onClick={() => {
                                    if (type === 'TRX') return;

                                    setType('TRX')
                                }}>
                                    <div className="left">
                                        <div className="inner"></div>
                                    </div>
                                    <div className="right">TRX </div>
                                </div>
                                <div className={type === 'USDT' ? "col3 active" : "col3"} onClick={() => {
                                    if (type === 'USDT') return;

                                    setType('USDT')
                                }}>
                                    <div className="left">
                                        <div className="inner"></div>
                                    </div>
                                    <div className="right">USDT </div>
                                </div>
                            </div>

                            <div className="tp--txt m2">Submit your withdrawal details:</div>
                            {type === 'INR' ? (
                                <>
                                    <input type="text" placeholder='Account holder name' onChange={(e) => setHolder(e.target.value)} value={holder} />
                                    <input type="number" placeholder='Bank Account Number' onChange={(e) => setNumber(e.target.value)} value={number} />
                                    <input type="text" placeholder='Bank IFSC Code' onChange={(e) => setIFSC(e.target.value.toUpperCase())} value={IFSC} />
                                </>
                            ) : (
                                    <input type="text" placeholder={`${type} TRC20 Address`} onChange={(e) => setAddress(e.target.value)} value={address} />
                            )}

                            <button onClick={addWallet}>Add Wallet</button>
                        </div>
                    </div>
                </div>
            </div> : <></>}

            <div className="header">
                <div className="top">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <GoArrowLeft />
                    </div>
                    <div className="text">Withdraw</div>
                </div>

                <div className="bottom">
                    <div className="balance">
                        <div className="icon">{symbol}</div>
                        <div className="amount">{parseFloat((balance / rate).toFixed(2))}</div>
                    </div>
                    <div className="txt">Available to withdraw</div>
                </div>
            </div>

            <div className="section">
                <div className="col">
                    <div className="head">
                        <div className='text'>Withdraw amount</div>
                        <div className="border"></div>
                    </div>

                    <div className="input">
                        <div className="icon">{symbol}</div>
                        <input type="number" value={amount} onChange={(e) => setAmount(e.target.value)} placeholder={`${parseFloat((200 / rate).toFixed(2))} - ${parseFloat((40000 / rate).toFixed(2))}`} />
                    </div>
                </div>

                <div className="col">
                    <div className="head">
                        <div className='text'>Select Wallet</div>
                        <div className="border"></div>
                    </div>

                    <div className="under">
                        <div className="options">
                            {wallet ? wallet.map((item, index) => {
                                return (
                                    <div className={`box ${item.type === 'INR' ? 'inr' : item.type === 'TRX' ? 'trx' : 'usdt'} ${(index + 1) === selected ? 'selected' : ''}`} key={index} onClick={() => {
                                        if (selected === (index + 1)) {
                                            setSelected(0)
                                        } else {
                                            setSelected(index + 1)
                                        }
                                    }}>
                                        <div className="top">
                                            {item.type === 'INR' ? <div className="left">
                                                <div className="tp--txt">Account No.</div>
                                                <div className="bp--txt">{item.accountNumber.toString().slice(0, 5)}XXXXX{item.accountNumber.toString().slice(-2)}</div>
                                            </div> : <div className="left">
                                                <div className="tp--txt">Address</div>
                                                <div className="bp--txt">{item.address.slice(0, 5)}***{item.address.slice(-2)}</div>
                                            </div>}
                                            <div className="right"></div>
                                        </div>
                                        <div className="watermark">{item.type}</div>
                                        {(index + 1) === selected ? <div className="selected">
                                            <div className="icon2">
                                                <GiCheckMark />
                                            </div>
                                        </div> : <></>}
                                    </div>
                                )
                            }) : <></>}

                            <div className="box blank" onClick={() => setPopup(true)}>
                                <center>
                                    <div className="icon">
                                        <IoMdAdd />
                                    </div>
                                    <div className="txt">Add Method</div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>

                <center>
                    <button onClick={onWithdraw}>Withdraw</button>
                </center>

                <div className="notice">
                    <div className="tp--txt">Notice</div>
                    <div className="bp--txt">-- INR Withdrawals can be done between 10:00 AM - 6:00 PM from monday to friday.</div>
                    <div className="bp--txt">-- Taxes for INR Withdrawals are 12%, for TRX 5% and for USDT 5% + 1 USDT.</div>
                    <div className="bp--txt">-- It can take upto 24hours to receive your withdrawals.</div>
                    <div className="bp--txt">-- You can withdraw 1 time in a day.</div>
                </div>
            </div>
        </div>
    )
}

export default Withdraw