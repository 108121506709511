import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './Pages/Home';
import Invest from './Pages/Invest';
import Team from './Pages/Team';
import Me from './Pages/Me';
import Reward from './Pages/Reward';
import Invite from './Pages/Invite';
import Recharge from './Pages/Recharge';
import Withdraw from './Pages/Withdraw';
import Records from './Pages/Records';
import Records2 from './Pages/Records2';
import Records3 from './Pages/Records3';
import Activity from './Pages/Activity';
import Register from './Pages/Register';
import Login from './Pages/Login';
import Rec from './Pages/Rec'

function App() {
  const forceHash = window.location.pathname !== '/' || (window.location.pathname === '/' && window.location.hash === '')

  if (forceHash) {
    if (window.location.pathname !== '/') {
      window.location.replace('/#' + window.location.pathname)
    } else {
      window.location.replace('/#/')
    }
  }

  const token = localStorage.getItem('token')
  const rate = localStorage.getItem('rate')
  const symbol = localStorage.getItem('symbol')

  return (
    <div className="App">
      <Router>
        {token ? <Routes>
          <Route path='*' element={<Home token={token} symbol={symbol} rate={rate} />} />
          <Route path='/invest' element={<Invest token={token} symbol={symbol} rate={rate} />} />
          <Route path='/team' element={<Team token={token} symbol={symbol} rate={rate} />} />
          <Route path='/reward' element={<Reward token={token} symbol={symbol} rate={rate} />} />
          <Route path='/invite' element={<Invite token={token} symbol={symbol} rate={rate} />} />
          <Route path='/pay' element={<Rec token={token} />} />
          <Route path='/me' element={<Me token={token} symbol={symbol} rate={rate} />} />
          <Route path='/activity' element={<Activity token={token} symbol={symbol} rate={rate} />} />
          <Route path='/recharge' element={<Recharge token={token} symbol={symbol} rate={rate} />} />
          <Route path='/withdraw' element={<Withdraw token={token} symbol={symbol} rate={rate} />} />
          <Route path='/records/withdrawal' element={<Records token={token} symbol={symbol} rate={rate} />} />
          <Route path='/records/recharge' element={<Records2 token={token} symbol={symbol} rate={rate} />} />
          <Route path='/my/transactions' element={<Records3 token={token} symbol={symbol} rate={rate} />} />
          <Route path='/register' element={<Register />} />
          <Route path='/login' element={<Login />} />
        </Routes> : <Routes>
          <Route path='*' element={<Login />} />
            <Route path='/register' element={<Register />} />
            <Route path='/login' element={<Login />} />
        </Routes>}
      </Router>
    </div>
  );
}

export default App;
