import React from 'react'
import '../CSS/Components/Bottom.css'

import { SolarEarthBoldDuotone, SolarGiftBoldDuotone, SolarShareCircleBoldDuotone, SolarUserRoundedBoldDuotone, UimHouseUser } from './SolarIcons';

const Bottom = ({ page }) => {
    const menu = [
        {
            item: 'Home',
            icon: <UimHouseUser color="#aaa" height='100%' width='100%' />,
            active: <UimHouseUser color="#4571ec" height='100%' width='100%' />,
            path: ''
        },
        {
            item: 'Invest',
            icon: <SolarEarthBoldDuotone color="#aaa" height='100%' width='100%' />,
            active: <SolarEarthBoldDuotone color="#4571ec" height='100%' width='100%' />,
            path: 'invest'
        },
        {
            item: 'Invite',
            icon: <SolarShareCircleBoldDuotone color="#aaa" height='100%' width='100%' />,
            active: <SolarShareCircleBoldDuotone color="#4571ec" height='100%' width='100%' />,
            path: 'invite'
        },
        {
            item: 'Reward',
            icon: <SolarGiftBoldDuotone color="#aaa" height='100%' width='100%' />,
            active: <SolarGiftBoldDuotone color="#4571ec" height='100%' width='100%' />,
            path: 'reward'
        },
        {
            item: 'Me',
            icon: <SolarUserRoundedBoldDuotone color="#aaa" height='100%' width='100%' />,
            active: <SolarUserRoundedBoldDuotone color="#4571ec" height='100%' width='100%' />,
            path: 'me'
        }
    ]

    return (
        <div className='bottom--nav'>
            {menu.map((item, index) => {
                return (
                    item.item === page ? <div className='col active' key={index}>
                        <center>
                            <div className="icon">
                                {item.active}
                            </div>

                        </center>
                    </div> : <div className='col' key={index} onClick={() => window.location.href = '/#/' + item.path}>
                        <center>
                            <div className="icon">
                                {item.icon}
                            </div>

                        </center>
                    </div>
                )
            })}
        </div>
    )
}

export default Bottom