import React, { useState, useEffect, useRef } from 'react'
import '../CSS/Auth.css'

import axios from 'axios'

import { IoMdArrowDropdown } from "react-icons/io";
import logo from '../Images/logo.png'
import '../CSS/Components/Popup.css'

import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import { IoKeyOutline } from "react-icons/io5";
import Loading from '../Components/Loading';
import Error from '../Components/Error';
import CryptoJS from 'crypto-js';
import Fingerprint2 from "fingerprintjs2";

import { AiOutlineUser } from "react-icons/ai";
import ReCAPTCHA from 'react-google-recaptcha';

const DELAY = 1500;

const Register = () => {
  let path = window.location.hash
  const getParams = path.split('?')[1]
  const query = new URLSearchParams(getParams);

  const [isd, setIsd] = useState('+91')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('');
  const [confirmPass, setConfirmPass] = useState('')
  const [name, setName] = useState('')
  const [inviter, setInviter] = useState(query.get('invite_code') ? query.get('invite_code') : '')
  const [otp, setOtp] = useState('')

  const [loader, setLoader] = useState(false)
  const [isdList, setIsdList] = useState([])

  const [popup, setPopup] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorText, setErrorText] = useState('')

  const [fingerprint, setFingerprint] = useState('')

  const [send, setSend] = useState(false)
  const [timer, setTimer] = useState(0)

  const [callback, setCallback] = useState("not fired");
  const [captchaToken, setCaptchaToken] = useState("[empty]");
  const [load, setLoad] = useState(false);
  const [expired, setExpired] = useState(false);
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);
  const reCaptchaRef = useRef();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoad(true);
    }, DELAY);
    return () => clearTimeout(timer);
  }, []);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (captchaToken === null) setExpired(true);
  };

  const asyncScriptOnLoad = () => {
    setCallback("called!");
    setRecaptchaLoaded(true);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Decrease the countdown by 1 second
      setTimer(prevTimer => prevTimer - 1);

      // Check if the countdown has reached zero
      if (timer === 0) {
        clearInterval(interval);
        // You can perform additional actions when the countdown reaches zero
        console.log('Countdown reached zero!');
      }
    }, 1000);

    // Clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, [timer]);



  Fingerprint2.get((components) => {
    const value = Fingerprint2.x64hash128(components.map((pair) => pair.value).join(""), 31);
    setFingerprint(value)
  });

  function generateSignature(originalFingerprint) {
    const signature = CryptoJS.HmacSHA256(originalFingerprint, process.env.REACT_APP_SECRET_KEY).toString();
    return { signature };
  }

  async function getIsdList() {
    setLoader(true)

    axios.get(`${process.env.REACT_APP_SERVER}/isd/list`)
      .then(response => {
        setLoader(false)

        let data = response.data;
        setIsdList(data)
      }).catch(error => {
        setIsError(true)
        setErrorText(error.response.data.message)

        const timerId = setTimeout(() => {
          setIsError(false)
          setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
      })
  }

  useEffect(() => {
    getIsdList()
  }, [])

  async function sendOtp() {
    if (!phone) {
      setIsError(true)
      setErrorText('Please enter your phone number')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (isNaN(phone) || phone.length !== 10) {
      setIsError(true)
      setErrorText('Please enter valid phone number')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    }

    setLoader(true)
    axios.post(`${process.env.REACT_APP_SERVER}/send`, { phone })
      .then(response => {
        setLoader(false)
        setSend(true)
        setTimer(120)
      }).catch(error => {
        setLoader(false)
        setIsError(true)
        setErrorText(error.response.data.message)

        const timerId = setTimeout(() => {
          setIsError(false)
          setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
      })
  }

  async function registerAcc() {
    if (!name) {
      setIsError(true)
      setErrorText('Please enter your name')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (!phone) {
      setIsError(true)
      setErrorText('Please enter your phone number')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (phone.length !== 10 || isNaN(phone)) {
      setIsError(true)
      setErrorText('Please enter valid phone number')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (!password) {
      setIsError(true)
      setErrorText('Please enter a password')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (password.length < 6) {
      setIsError(true)
      setErrorText('Password is less than 6 bits')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (!confirmPass) {
      setIsError(true)
      setErrorText('Please confirm your password')

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (confirmPass !== password) {
      setIsError(true)
      setErrorText(`Confirm password didn't matched`)

      const timerId = setTimeout(() => {
        setIsError(false)
        setErrorText('')
      }, 1500);

      return () => clearTimeout(timerId);
    } else if (isd === '+91' && !send) {
      return sendOtp()
    }

    setLoader(true)

    reCaptchaRef.current.executeAsync().then((value) => {
      axios.post(`${process.env.REACT_APP_SERVER}/register`, {
        isd, phone, password, confirmPass, signature: generateSignature(fingerprint).signature, fingerprint, name, inviter, captchaToken: value, otp
      })
        .then(response => {
          window.location.href = '/#/login'
        }).catch(error => {
          setLoader(false)
          setIsError(true)
          setErrorText(error.response.data.message)

          const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
          }, 1500);

          return () => clearTimeout(timerId);
        })
    }) 
  }

  return (
    <div className='login'>
      {isError ? <Error errorMsg={errorText} /> : <></>}
      {loader ? <Loading /> : <></>}

      {popup ? <div className='popup--screen'>
        <div className="popup--canceller" onClick={() => setPopup(!popup)}></div>
        <div className="main">
          <div className="popup--top">
            <div className="left">Select Country</div>
            <div className="right">
              <div className="icon" onClick={() => setPopup(!popup)}>
                <RxCross2 />
              </div>
            </div>
          </div>

          <div className="popup--body">
            {isdList.map((item, index) => {
              return (
                <div className="col" key={index} onClick={() => { setIsd(item.ISD_code); setPopup(!popup) }}>
                  <div className="left">
                    <div className="code">{item.ISD_code}</div>
                    <div className="country">{item.country}</div>
                  </div>

                  {item.ISD_code === isd ? <div className="right">
                    <IoCheckmarkDoneSharp />
                  </div> : <></>}
                </div>
              )
            })}

          </div>
        </div>
      </div> : <></>}

      <center>
        <div className="logo">
          <img src={logo} height={'120'} alt="" />
        </div>
      </center>

      <div className="form">
        <center>
          <div className="title type2">Register</div>
          <div className="border"></div>
        </center>

        <div className="input">
          <div className="left">
            <div className="icon">
              <AiOutlineUser />
            </div>
          </div>
          <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder='Full name' />
        </div>
        <div className="input type2">
          <div className="left" onClick={() => setPopup(!popup)}>
            <div className="code">
              {isd}
            </div>
            <div className="icon">
              <IoMdArrowDropdown />
            </div>
          </div>
          <input type="tel" maxLength={10} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone no' />
        </div>
        <div className="input">
          <div className="left">
            <div className="icon">
              <IoKeyOutline />
            </div>
          </div>
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
        </div>
        <div className="input">
          <div className="left">
            <div className="icon">
              <IoKeyOutline />
            </div>
          </div>
          <input type="password" value={confirmPass} onChange={(e) => setConfirmPass(e.target.value)} placeholder='Confirm password' />
        </div>

        {send && (
          <div className="input2">
            <input type="number" value={otp} onChange={(e) => setOtp(e.target.value)} placeholder='Enter OTP' />
            <button className="otp--send" onClick={sendOtp}>{send && timer > 0 ? `${timer}s` : send && !timer ? 'Resend' : 'Send'}</button>
          </div>
        )}

        {load && (<ReCAPTCHA ref={reCaptchaRef} asyncScriptOnLoad={asyncScriptOnLoad} sitekey="6Le_b24pAAAAANg0_lCv7xcFyjke5MztbcfXgCyZ" onChange={handleCaptchaChange} size="invisible" />)}

        <center>
          <button className="login--btn" onClick={registerAcc}>Create Account</button>
          <div className="text">
            <div className="txt">Already have an account?</div><div className='link' onClick={() => window.location.href = '/#/'}>Login now</div>
          </div>
        </center>

      </div>
    </div>
  )
}

export default Register