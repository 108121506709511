import React, { useState, useEffect } from 'react'
import '../CSS/Team.css'

import Bottom from '../Components/Bottom'

import axios from 'axios'
import Error from '../Components/Error'
import Loading from '../Components/Loading'

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

import img from '../Images/no-data.svg'

const Team = ({ token, symbol, rate }) => {
    const navigate = useNavigate()

    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    const [level, setLevel] = useState(query.get('level') ? query.get('level') : 1)
    const [commission, setCommission] = useState(0)
    const [invites, setInvites] = useState(0)
    const [records, setRecords] = useState([])

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/team?level=${level}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setRecords(data.records)
                setCommission(data.commission)
                setInvites(data.invites);

                setLoader(false)

            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [level])


    return (
        <div className='team'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="top">
                <div className="icon" onClick={() => navigate(-1)}>
                    <GoArrowLeft />
                </div>
                <div className="text">My Team</div>
            </div>

            <div className="section">
                <div className="selector">
                    <div className={level === 1 ? "col active" : "col"} onClick={() => {
                        if (level === 1) return;
                        setLevel(1)
                    }}>Level 1</div>
                    <div className={level === 2 ? "col active" : "col"} onClick={() => {
                        if (level === 2) return;
                        setLevel(2)
                    }}>Level 2</div>
                    <div className={level === 3 ? "col active" : "col"} onClick={() => {
                        if (level === 3) return;
                        setLevel(3)
                    }}>Level 3</div>
                </div>

                <div className="record">
                    <div className="total">
                        <div className="col">
                            <center>
                                <div className="col--tp">{invites}</div>
                                <div className="col--bp">Total Invites</div>
                            </center>
                        </div>
                        <div className="col">
                            <center>
                                <div className="col--tp">{symbol}{parseFloat((commission / rate).toFixed(2))}</div>
                                <div className="col--bp">Commission</div>
                            </center>
                        </div>
                    </div>

                    {records.length === 0 ? <div className="no--data">
                        <center>
                            <img src={img} height={'180'} alt="" />
                            <div className="no--data--txt">No Referral Found</div>
                        </center>
                    </div> : records.map((item, index) => {
                        let date = new Date(item.date)
                        let mon = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

                        let am = date.getHours() > 12 ? 'PM' : 'AM'

                        let nd = ("0" + date.getDate()).slice(-2) + ' ' + mon[date.getMonth()] + ' ' + date.getFullYear()
                        let na = ("0" + (date.getHours() - 12)).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2)

                        return (
                            <div className="data" key={index}>
                                <div className="box">
                                    <div className="col">
                                        <div className="left">User ID</div>
                                        <div className="right">{item.user}</div>
                                    </div>
                                    <div className="col">
                                        <div className="left">Investments</div>
                                        <div className="right">{symbol}{parseFloat((item.deposits ? item.deposits : 0 / rate).toFixed(2))}</div>
                                    </div>
                                    <div className="col">
                                        <div className="left">Commission</div>
                                        <div className="right">{symbol}{parseFloat((item.income ? item.income: 0 / rate).toFixed(2))}</div>
                                    </div>
                                    <div className="col">
                                        <div className="left">Date</div>
                                        <div className="right">{nd}, {na} {am}</div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Bottom page='Team' />
        </div>
    )
}

export default Team