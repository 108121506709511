import React, { useState, useEffect } from 'react'
import '../CSS/Invite.css'
import axios from 'axios'

import Bottom from '../Components/Bottom'

import img1 from '../Images/Invite/top-img.png'
import img2 from '../Images/Invite/coin-img.png'

import lv1 from '../Images/Invite/lv1.png'
import lv2 from '../Images/Invite/lv2.png'
import lv3 from '../Images/Invite/lv3.png'

import Error from '../Components/Error';
import Loading from '../Components/Loading';

import copy from 'copy-to-clipboard';

const Invite = ({ token, rate, symbol }) => {
    const [id, setId] = useState('XXXXXXXX');
    const [commission, setCommission] = useState(0.00)
    const [investments, setInvestments] = useState(0.00)
    const [users, setUsers] = useState(0);

    const [lv1Data, setLv1Data] = useState({
        commission: 0,
        investments: 0
    })

    const [lv2Data, setLv2Data] = useState({
        commission: 0,
        investments: 0
    })

    const [lv3Data, setLv3Data] = useState({
        commission: 0,
        investments: 0
    })

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);


    const link = `${window.location.origin}/#/register?invite_code=${id}`

    function copyLink() {
        copy(link)
        setIsError(true)
        setErrorText('Copied')

        const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
    }

    async function getMe() {
        setLoader(true);

        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER}/invite`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response && response.data) {
                const data = response.data;

                setId(data.id)
                setCommission(data.commissions)
                setUsers(data.users)
                setInvestments(data.investments)

                setLv1Data({
                    commission: data.lv1.commission,
                    investments: data.lv1.investments
                })

                setLv2Data({
                    commission: data.lv2.commission,
                    investments: data.lv2.investments
                })

                setLv3Data({
                    commission: data.lv3.commission,
                    investments: data.lv3.investments
                })
            }

            setLoader(false);
        } catch (error) {
            setLoader(false);
            setIsError(true);
            setErrorText(error.response ? error.response.data.message : 'An error occurred');

            const timerId = setTimeout(() => {
                setIsError(false);
                setErrorText('');
            }, 1500);

            return () => clearTimeout(timerId);
        }
    }

    useEffect(() => {
        getMe()
    }, [])


    return (
        <div className='invite'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="invite--top">
                <img src={img1} width={'100%'} alt="" />
            </div>
            <div className="invite--bottom">
                <div className="inv--u">
                    <div className="inv--top">
                        <div className="inv--u--top">
                            <div className="no">{symbol}{parseFloat((commission / rate).toFixed(2))}</div>
                            <div className="text">Obtaining Commission</div>
                        </div>
                        <div className="inv--u--bottom">
                            <div className="col">
                                <div className="no">{users}</div>
                                <div className="text">Registration</div>
                            </div>
                            <div className="col">
                                <div className="no">{symbol}{parseFloat((investments / rate).toFixed(2))}</div>
                                <div className="text">Investments</div>
                            </div>
                        </div> <br />
                        <div className="inv--buttons">
                            <button className="link--btn" onClick={copyLink}>Copy Invite Link</button>
                        </div>
                    </div>

                    <div className="rewards--list">
                        <div className="col">
                            <div className="head">
                                <div className='text'>REWARD 1</div>
                                <div className="border"></div>
                            </div>
                            <div className="col--top">
                                <div className="col--u">
                                    <div className="col--u--top">10<span>%</span></div>
                                    <div className="col--u--bottom">Level 1</div>
                                </div>
                                <div className="col--u">
                                    <div className="col--u--top">4<span>%</span></div>
                                    <div className="col--u--bottom">Level 2</div>
                                </div>
                                <div className="col--u">
                                    <div className="col--u--top">2<span>%</span></div>
                                    <div className="col--u--bottom">Level 3</div>
                                </div>
                            </div>
                            <div className="col--bottom">
                                <div className="txt">If your friends invest in our platform, you can get upto 12% commission of the total amount.</div>
                            </div>
                        </div> <br />
                        <div className="col">
                            <div className="head">
                                <div className='text'>REWARD 2</div>
                                <div className="border"></div>
                            </div>
                            <div className="col--under">
                                <div className="col--left">
                                    <img src={img2} alt="" />
                                </div>
                                <div className="col--right">
                                    <div className="txt">If your friends make his first deposit, it will be counted as verified referral and you can receive reward.</div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="team">
                        <div className="head" style={{ marginBottom: 20}}>
                            <div className='text'>MY TEAM</div>
                            <div className="border"></div>
                        </div>
                        <div className="col">
                            <div className="left">
                                <img src={lv1} width={'100%'} alt="" />
                            </div>
                            <div className="right">
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv1Data.investments / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Invested</div>
                                </div>
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv1Data.commission / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Commission</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="left">
                                <img src={lv2} width={'100%'} alt="" />
                            </div>
                            <div className="right">
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv2Data.investments / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Invested</div>
                                </div>
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv2Data.commission / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Commission</div>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="left">
                                <img src={lv3} width={'100%'} alt="" />
                            </div>
                            <div className="right">
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv3Data.investments / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Invested</div>
                                </div>
                                <div className="right--col">
                                    <div className="col--top">{symbol}{parseFloat((lv3Data.commission / rate).toFixed(2))}</div>
                                    <div className="col--bottom">Commission</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Bottom page="Invite" />
        </div>
    )
}

export default Invite