import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ targetDate, method, day, period }) => {
    const calculateTimeRemaining = () => {
        const now = new Date()
        const difference = new Date(targetDate) - now;

        if (difference <= 0) {
            return { days: 0, hours: 0, minutes: 0, seconds: 0 };
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const formatTime = (time) => (time < 10 ? `0${time}` : time);

    const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining);

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [targetDate]);

    // Update the timer when the targetDate prop changes
    useEffect(() => {
        setTimeRemaining(calculateTimeRemaining());
    }, [targetDate]);

    const isCountdownOver = timeRemaining.days === 0 && timeRemaining.hours === 0 && timeRemaining.minutes === 0 && timeRemaining.seconds === 0;

    return (
        <div className="timer">
            {isCountdownOver
                ? "Adding Revenue..."
                : timeRemaining.days > 0
                    ? `${timeRemaining.days}d ${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`
                    : `${formatTime(timeRemaining.hours)}:${formatTime(timeRemaining.minutes)}:${formatTime(timeRemaining.seconds)}`}
        </div>
    );
};

export default CountdownTimer;
