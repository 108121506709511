import React, { useState, useEffect } from 'react'
import '../CSS/Me.css'
import Bottom from '../Components/Bottom'
import axios from 'axios'

import { IoIosArrowForward } from "react-icons/io";

import logo from '../Images/logo.png'
import Error from '../Components/Error';
import Loading from '../Components/Loading';
import { SolarCircleBottomDownBoldDuotone, SolarCircleBottomUpBoldDuotone, SolarCloudDownloadBoldDuotone, SolarHeadphonesRoundBoldDuotone, SolarLogin3BoldDuotone, SolarPlainBoldDuotone, SolarUsersGroupTwoRoundedBoldDuotone2 } from '../Components/SolarIcons';

const Me = ({ token, rate, symbol }) => {
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [name, setName] = useState('XXXXX XXX')
    const [phone, setPhone] = useState('+1 1234XXXX90')

    const [myIncome, setMyIncome] = useState(0)
    const [teamIncome, setTeamIncome] = useState(0)
    const [balance, setBalance] = useState(0)

    const options = [
        {
            item: 'My Cashouts',
            icon: <SolarCircleBottomUpBoldDuotone height="40px" width="40px" />,
            path: () => {
                window.location.href = '/#/records/withdrawal'
            }
        },
        {
            item: 'My Recharges',
            icon: <SolarCircleBottomDownBoldDuotone height="40px" width="40px" />,
            path: () => {
                window.location.href = '/#/records/recharge'
            }
        },
        {
            item: 'My Team',
            icon: <SolarUsersGroupTwoRoundedBoldDuotone2 height="40px" width="40px" />,
            path: () => {
                window.location.href = '/#/team'
            }
        },
        {
            item: 'Support',
            icon: <SolarHeadphonesRoundBoldDuotone height="40px" width="40px" />,
            path: () => {
                //'optiwealthsupport1', 'optiweath', 
                const links = ['opti_globals'];

                const randomIndex = Math.floor(Math.random() * links.length);
                const randomLink = links[randomIndex];

                window.open('tg://resolve?domain=' + randomLink)
            }
        },
        {
            item: 'Join Us',
            icon: <SolarPlainBoldDuotone height="40px" width="40px" />,
            path: () => {
                window.open('tg://resolve?domain=opti_global')
            }
        },
        {
            item: 'Logout',
            icon: <SolarLogin3BoldDuotone height="40px" width="40px" />,
            path: () => {
                localStorage.removeItem('token');
                window.location.reload()
            }
        }
    ]

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/home`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setName(data.name);
                setPhone(data.isd + ' ' + data.phone)

                setMyIncome(data.investments)
                setTeamIncome(data.withdraw)
                setBalance(data.totalBalance)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='me'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="me--top">
                <div className="top">
                    <div className="left">
                        <img src={logo} height={'55px'} alt="" />
                        <div className="left--right">
                            <div className="name">{name}</div>
                            <div className="phone">{phone}</div>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div className="col">
                        <center>
                            <div className="col--top">{symbol}{parseFloat((myIncome / rate).toFixed(2))}</div>
                            <div className="col--bottom">My Investments</div>
                        </center>
                    </div>
                    <div className="col">
                        <center>
                            <div className="col--top">{symbol}{parseFloat((teamIncome / rate).toFixed(2))}</div>
                            <div className="col--bottom">My Income</div>
                        </center>
                    </div>
                </div>
            </div>

            <div className="me--section">
                <div className="top">
                    <div className="box">
                        <div className="box--top">
                            <div className="left">
                                <div className="balance">{symbol}{parseFloat((balance / rate).toFixed(2))}</div>
                                <div className="txt">Total Balance</div>
                            </div>
                            <div className="right" onClick={() => window.location.href = '/#/my/transactions'}>
                                <div className="col">Record</div>
                                <div className="icon">
                                    <IoIosArrowForward />
                                </div>
                            </div>
                        </div>
                        <div className="box--bottom">
                            <div className="left">View all transactions</div>
                            <div className="right">
                                <button className='history--btn' onClick={() => window.location.href = '/#/my/transactions'}>History</button>
                                <button className='withdrawal--btn' onClick={() => window.location.href = '/#/withdraw'}>Withdraw</button>
                            </div>
                        </div>
                    </div>
                    
                    <div className="options">
                        {options.map((item, index) => {
                            return (
                                <div key={index} className='col' onClick={item.path}>
                                    <center>
                                        <div className="col--icon">
                                            {item.icon}
                                        </div>
                                        <div className="col--name">{item.item}</div>
                                    </center>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <Bottom page='Me' />
        </div>
    )
}

export default Me