import React, { useState, useEffect } from 'react'
import '../CSS/Activity.css'

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import Error from '../Components/Error';
import Loading from '../Components/Loading';

import img from '../Images/act.svg'

import axios from 'axios'

const Activity = ({ token, rate, symbol }) => {
    const navigate = useNavigate();

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [checkIn, setCheckIn] = useState(false);
    const [deposit, setDeposit] = useState('pending')
    const [withdraw, setWithdraw] = useState('pending')
    const [referral, setReferral] = useState('pending')

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/activity`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setCheckIn(data.checkIn)
                setDeposit(data.deposit);
                setReferral(data.referrals)
                setWithdraw(data.withdraw)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    async function claimReward(task) {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/claim/task?task=${task}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                if (task === 'check-in') {
                    setCheckIn(false)
                } else if (task === 'deposit') {
                    setDeposit('claimed')
                } else if (task === 'referral') {
                    setReferral('claimed')
                } else if (task === 'withdraw') {
                    setWithdraw('claimed')
                }

                setLoader(false)

                setIsError(true)
                setErrorText('Claimed Successfully')

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='activity'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="top">
                <div className="icon" onClick={() => navigate(-1)}>
                    <GoArrowLeft />
                </div>
                <div className="text">Activity</div>
            </div>

            <div className="section">
                <div className="head">
                    <div className='text'>DAILY CHECKIN</div>
                    <div className="border"></div>
                </div>
                <div className="col">
                    <div className="left">
                        <div className="icon">
                            <img src={img} alt="" />
                        </div>
                        <div className="col--right">Sign In daily to receive free checkin reward of {symbol}{parseFloat((8 / rate).toFixed(2))}</div>
                    </div>
                    <div className="right">
                        {checkIn ? <button onClick={() => claimReward('check-in')}>Claim</button> : <button className='disabled'>Claimed</button>}
                    </div>
                </div> <br />
                <div className="head">
                    <div className='text'>REFERRAL TASK</div>
                    <div className="border"></div>
                </div>
                <div className="col">
                    <div className="left">
                        <div className="icon">
                            <img src={img} alt="" />
                        </div>
                        <div className="col--right">Invite 20 friends to claim free product and receive {symbol}{parseFloat((100 / rate).toFixed(2))}</div>
                    </div>
                    <div className="right">
                        {referral === 'pending' ? <button className='disabled'>Incomplete</button> : referral === 'claim' ? <button onClick={() => claimReward('referral')}>Claim</button> : <button className='disabled'>Claimed</button>}
                    </div>
                </div> <br />
                <div className="head">
                    <div className='text'>OTHER TASK</div>
                    <div className="border"></div>
                </div>
                <div className="col">
                    <div className="left">
                        <div className="icon">
                            <img src={img} alt="" />
                        </div>
                        <div className="col--right">Make your first deposit and receive {symbol}{parseFloat((40 / rate).toFixed(2))} bonus</div>
                    </div>
                    <div className="right">
                        {deposit === 'pending' ? <button className='disabled'>Incomplete</button> : deposit === 'claim' ? <button onClick={() => claimReward('deposit')}>Claim</button> : <button className='disabled'>Claimed</button>}
                    </div>
                </div>
                <div className="col m">
                    <div className="left">
                        <div className="icon">
                            <img src={img} alt="" />
                        </div>
                        <div className="col--right">Share your first withdrawal proof with us and receive bonus of {symbol}{parseFloat((25 / rate).toFixed(2))} for free</div>
                    </div>
                    <div className="right">
                        {withdraw === 'pending' ? <button className='disabled'>Incomplete</button> : withdraw === 'share' ? <button onClick={() => {
                            window.open('tg://resolve?domain=optiwealthsupport')
                        }}>Share</button> : withdraw === 'claim' ? <button onClick={() => claimReward('withdraw')}>Claim</button> : <button className='disabled'>Claimed</button>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activity