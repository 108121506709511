import React, { useState, useEffect } from 'react'
import '../CSS/Invest.css'
import Bottom from '../Components/Bottom'

import axios from 'axios'
import Error from '../Components/Error'
import Loading from '../Components/Loading'

import img from '../Images/no-data.svg'
import CountdownTimer from '../Components/CountdownTimer'

const Invest = ({ token, rate, symbol }) => {
    const [records, setRecords] = useState([])
    const [investments, setInvestments] = useState(0)
    const [revenue, setRevenue] = useState(0)

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/investments`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data;

                setRecords(data.records)
                setInvestments(data.investments)
                setRevenue(data.revenue);

                setLoader(false)

            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])
    

    return (
        <div className='invest' style={{ paddingBottom: 80}}>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="top">
                <div className="box"></div>
            </div>

            <div className="record">
                <div className="box">
                    <div className="col">
                        <center>
                            <div className="col--tp">{symbol}{parseFloat((investments / rate).toFixed(2))}</div>
                            <div className="col--bp">Invested</div>
                        </center>
                    </div>
                    <div className="col">
                        <center>
                            <div className="col--tp">{symbol}{parseFloat((revenue / rate).toFixed(2))}</div>
                            <div className="col--bp">Return</div>
                        </center>
                    </div>
                </div>

                {records.length === 0 ? <div className="no--data">
                    <center>
                        <img src={img} height={'180'} alt="" />
                        <div className="no--data--txt">No Investment Found</div>
                    </center>
                </div> : <div className="data">
                    {records.map((item, index) => {
                        let ro = item.amount;

                        let period = item.method === false ? item.period / 4 : item.period

                        for (let i = 0; i < period; i++) {
                            ro += ro * (item.daily / 100)
                        }

                        let date = new Date(item.date + (1000 * 60 * 60 * 24 * period))
                        let mon = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

                        let nd = ("0" + date.getDate()).slice(-2) + ' ' + mon[date.getMonth()] + ' ' + date.getFullYear()

                        let nxtDate = item.method === true ? item.lastRun + (1000 * 60 * 60 * 24) : date;

                        let dr = item.amount === 0 ? 12 : parseFloat(((item.amount * (item.daily / 100)) / rate).toFixed(2))

                        return (
                            <div className="col" key={index}>
                                <div className="col--top">
                                    <div className="left">
                                        <div className="plan--name">{item.name}</div>
                                    </div>
                                    <div className="right">
                                        {item.method ? <CountdownTimer targetDate={nxtDate} method={item.method} day={item.day} period={period} /> : <CountdownTimer targetDate={nxtDate} method={item.method} day={item.day} period={period} />}
                                    </div>
                                </div>

                                <div className="breaker">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>

                                <div className="col--bottom"> <br />
                                    <div className="new--col">
                                        <div className="col--left">Investment Type</div>
                                        <div className="col--right">{item.method === true ? 'Daily Return' : 'Compound Return'}</div>
                                    </div>
                                    {item.method === true ? <div className="new--col">
                                        <div className="col--left">Revenue Generated</div>
                                        <div className="col--right">{symbol}{parseFloat((item.total / rate).toFixed(2))}</div>
                                    </div> : <></>}
                                    <div className="new--col">
                                        <div className="col--left">Expected Return</div>
                                        <div className="col--right">{symbol}{item.method === true ? parseFloat(((dr * period) / rate).toFixed(2)) : parseFloat(ro.toFixed(2))}</div>
                                    </div>
                                    <div className="new--col">
                                        <div className="col--left">Expire Date</div>
                                        <div className="col--right">{nd}</div>
                                    </div>
                                    {item.method === true ? <div className="new--col">
                                        <div className="col--left">Period Left</div>
                                        <div className="col--right">{period - item.day} Days</div>
                                    </div> : <></>}
                                </div>
                            </div>
                        )
                    })}
                </div>}

                
            </div>

            <Bottom page='Invest' />
        </div>
    )
}

export default Invest