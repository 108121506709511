import React, { useState, useEffect } from 'react'
import '../CSS/Rec.css'
import axios from 'axios'

import logo from '../Images/logo.png'

import { IoCopyOutline } from "react-icons/io5";

import Error from '../Components/Error';
import Loading from '../Components/Loading';
import copy from 'copy-to-clipboard';

const Rec = ({ token }) => {
    let path = window.location.hash
    const getParams = path.split('?')[1]
    const query = new URLSearchParams(getParams);

    let id = query.get('id')

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const [details, setDetails] = useState({})
    const [targetTimestamp, setTargetTimestamp] = useState(/* Set your initial timestamp here */);
    const [timeRemaining, setTimeRemaining] = useState();

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimeRemaining(calculateTimeRemaining());
        }, 1000);

        return () => clearInterval(intervalId);
    }, [targetTimestamp]); // Run the effect whenever targetTimestamp changes

    const calculateTimeRemaining = () => {
        if (!targetTimestamp || isNaN(targetTimestamp)) {
            return { minutes: 0, seconds: 0 };
        }

        const now = new Date();
        const targetDate = new Date(targetTimestamp + (30 * 60000));
        const difference = targetDate - now;

        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);

        return { minutes: minutes < 1 ? 0 : minutes, seconds: seconds < 1 ? 0 : seconds };
    };

    async function getData() {
        setLoader(true)
        axios.get(`${process.env.REACT_APP_SERVER}/recharge/details?order_id=${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let date = data.date

                setDetails(data)
                setTargetTimestamp(date)
                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }


    useEffect(() => {
        getData()
    }, [])

    function copyLink(link) {
        copy(link)
        setIsError(true)
        setErrorText('Copied')

        const timerId = setTimeout(() => {
            setIsError(false)
            setErrorText('')
        }, 1500);

        return () => clearTimeout(timerId);
    }

    return (
        <div className='pay'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="top">
                <div className="left">
                    <img src={logo} alt="" width={'100%'} />
                </div>

                <div className="right">
                    <div className="name">Opti Wealth</div>
                    <div className="order--id">#{id}</div>

                </div>
            </div>

            <div className="bottom">
                <div className="box">
                    <div className={details.status === 'Pending' ? "box--top pc" : details.status === 'Success' ? "box--top sc" : "box--top fc"}>
                        <div className="left">{details.status === 'Pending' ? timeRemaining && timeRemaining.minutes === 0 && timeRemaining.seconds === 0 ? 'Order Expired' : 'Expiring In' : details.status === 'Success' ? 'Paid Successfully' : details.status === 'Failed' ? 'Recharge Failed' : ''}</div>
                        <div className="right">{
                            details.status === 'Pending' ? (
                                (timeRemaining && timeRemaining.minutes < 10 ? '0' + timeRemaining.minutes : (timeRemaining && timeRemaining.minutes ? timeRemaining.minutes : '00')) +
                                ':' +
                                (timeRemaining && timeRemaining.seconds < 10 ? '0' + timeRemaining.seconds : (timeRemaining && timeRemaining.seconds ? timeRemaining.seconds : '00'))
                            ) : ''
                        }</div>
                    </div>

                    <div className="col">
                        <div className="left">
                            <div className="col--top">{details.status === 'Pending' ? 'Amount to pay' : details.status === 'Success'? 'Amount Paid' : 'Amount to pay' }</div>
                            <div className="col--bottom">{parseFloat((details.amount)?.toFixed(3))} {details.type} <span>(trc20)</span></div>
                        </div>

                        {details.status === 'Pending' ? <div className="right">
                            <IoCopyOutline onClick={() => copyLink(parseFloat((details.amount)?.toFixed(3)))} />
                        </div> : <></>}
                    </div>
                    <div className="col">
                        <div className="left">
                            <div className="col--top">Payment Address</div>
                            <div className="col--bottom">{details.address}</div>
                        </div>

                        {details.status === 'Pending' ? <div className="right">
                            <IoCopyOutline onClick={() => copyLink(details.address)} />
                        </div> : <></>}
                    </div>

                    {details.status === 'Pending' ? <div className="si--txt">* IMPORTANT NOTICE: Paying amount less than the order value, can result in your lose. <br /><br />Use the above address to pay once</div> : <div className="si--txt">* IMPORTANT NOTICE: Please dont make any payment on the above address as this order already closed</div>}
                </div>
            </div>

            <center>
                <div className="bt--txt">Powered by <span>Asset Avenue</span></div>
            </center>
        </div>
    )
}

export default Rec