import React, { useState, useEffect } from 'react'
import '../CSS/Reward.css';
import Bottom from '../Components/Bottom';

import Error from '../Components/Error';
import Loading from '../Components/Loading';

import axios from 'axios'

import img1 from '../Images/VIP/Picsart_24-01-14_16-41-33-106.png'
import img2 from '../Images/VIP/Picsart_24-01-14_16-41-51-083.png'
import img3 from '../Images/VIP/Picsart_24-01-14_16-42-20-686.png'
import img4 from '../Images/VIP/Picsart_24-01-14_16-42-44-778.png'
import img5 from '../Images/VIP/Picsart_24-01-14_16-43-09-910.png'
import img6 from '../Images/VIP/Picsart_24-01-14_16-43-39-990.png'
import img7 from '../Images/VIP/Picsart_24-01-14_16-44-28-527.png'
import img8 from '../Images/VIP/Picsart_24-01-14_16-44-59-615.png'
import img9 from '../Images/VIP/Picsart_24-01-27_22-33-27-433.png'

const Reward = ({ token, rate, symbol }) => {
    const [levels, setLevels] = useState([]);
    const [referrals, setReferrals] = useState(0)

    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    const arr = [
        {
            item: 5,
            img: img8
        },
        {
            item: 10,
            img: img7
        },
        {
            item: 25,
            img: img6
        },
        {
            item: 50,
            img: img5
        },
        {
            item: 100,
            img: img4
        },
        {
            item: 250,
            img: img3
        },
        {
            item: 500,
            img: img2
        },
        {
            item: 1000,
            img: img1
        },
        {
            item: 2500,
            img: img9
        }
    ]

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/reward`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setLevels(data.levels);
                setReferrals(data.referrals)

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])


    async function claimReward(level) {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/claim/reward?level=${level}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                setLevels(data.levels);
                setReferrals(data.referrals)

                setLoader(false)

                setIsError(true)
                setErrorText('Claimed Successfully')

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='reward'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            

            <div className="section">
                {arr.map((item, index) => {
                    let isLevelClaimed = levels.includes(index + 1);
                    let isAvailableToClaim = referrals >= item;

                    return (
                        <div className="col" key={index}>
                            <img src={item.img} height={'45'} alt="" />
                            <div className="col--right">
                                <div className="col--top">Invite <span>{item.item}</span> Friends to make a deposit of atleast <span>{symbol}{parseFloat((600 / rate).toFixed(2))}</span> to receive <span>{symbol}{parseFloat(((item.item * 50) / rate).toFixed(2))}</span> cash</div>
                                <div className="col--bottom">
                                    <div className="left">Invites: {referrals > item.item ? item.item : referrals } / {item.item}</div>
                                    {isLevelClaimed ? <button className="claim--btn disabled">Claimed</button> : isAvailableToClaim ? <button className="claim--btn" onClick={() => claimReward(index + 1)}>Claim</button> : <button className="claim--btn disabled">Claim</button>}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>

            <Bottom page="Reward" />
        </div>
    )
}

export default Reward