import React, { useState, useEffect } from 'react'
import '../CSS/Records.css'
import axios from 'axios';

import { GoArrowLeft } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

import Error from '../Components/Error';
import Loading from '../Components/Loading';
import img from '../Images/no-data.svg'

const Records3 = ({ token, rate, symbol }) => {
    const navigate = useNavigate()

    const [records, setRecords] = useState([])
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')
    const [loader, setLoader] = useState(false);

    async function getData() {
        setLoader(true);

        axios.get(`${process.env.REACT_APP_SERVER}/transactions`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                let data = response.data

                let records = data.records;
                setRecords(records);

                setLoader(false)
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className='records'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            <div className="header">
                <div className="top">
                    <div className="icon" onClick={() => navigate(-1)}>
                        <GoArrowLeft />
                    </div>
                    <div className="text">My Transactions</div>
                </div>
            </div>

            <div className="section">
                {records.length === 0 ? <div className="no--data">
                    <center>
                        <img src={img} height={'180'} alt="" />
                        <div className="no--data--txt">No Record Found</div>
                    </center>
                </div> : records.map((item, index) => {

                    let date = new Date(item.date)
                    let mon = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

                    let nd = ("0" + date.getDate()).slice(-2) + ' ' + mon[date.getMonth()] + ' ' + date.getFullYear()
                    let nt = ("0" + (date.getHours() > 24 ? date.getHours() - 12 : date.getHours())).slice(-2) + ':' + ("0" + date.getMinutes()).slice(-2) + ':' + ("0" + date.getSeconds()).slice(-2)
                    let na = date.getHours() > 12 ? 'PM' : 'AM'

                    return (
                        <div key={index} className='box'>
                            <div className="top">
                                <div className="left">
                                    <div className="tp--txt">{item.item}</div>
                                    <div className="bp--txt">{nd} {nt} {na}</div>
                                </div>
                                <div className="right">
                                    <div className={item.type === true ? "col2 approved2" : "col2 failed2"}>{item.type === true ? '+' : '-'}{symbol}{parseFloat((item.amount / rate).toFixed(2))}</div>
                                </div>
                            </div>
                        </div>
                    )
                }) }
            </div>
        </div>
    )
}

export default Records3