import React, { useEffect, useState } from 'react'
import '../CSS/Auth.css'

import logo from '../Images/logo.png'

import { IoKeyOutline } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import Loading from '../Components/Loading';

import '../CSS/Components/Popup.css'

import { RxCross2 } from "react-icons/rx";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

import axios from 'axios'
import Error from '../Components/Error';

const Login = () => {
    const [isd, setIsd] = useState('+91')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('');

    const [loader, setLoader] = useState(false)
    const [isdList, setIsdList] = useState([])

    const [popup, setPopup] = useState(false)
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('')

    async function getIsdList() {
        setLoader(true)

        axios.get(`${process.env.REACT_APP_SERVER}/isd/list`)
            .then(response => {
                setLoader(false)

                let data = response.data;
                setIsdList(data)
            }).catch(error => {
                setIsError(true)
                setErrorText(error.response.data.error)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    useEffect(() => {
        getIsdList()
    }, [])

    async function loginAcc() {
        if (!phone) {
            setIsError(true)
            setErrorText('Please enter your phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (phone.length !== 10 || isNaN(phone)) {
            setIsError(true)
            setErrorText('Please enter valid phone number')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (!password) {
            setIsError(true)
            setErrorText('Please enter a password')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        } else if (password.length < 6) {
            setIsError(true)
            setErrorText('Password is less than 6 bits')

            const timerId = setTimeout(() => {
                setIsError(false)
                setErrorText('')
            }, 1500);

            return () => clearTimeout(timerId);
        }

        setLoader(true)
        axios.post(`${process.env.REACT_APP_SERVER}/login`, {
            isd, phone, password
        })
            .then(response => {
                let data = response.data;
                const token = data.token

                localStorage.setItem('token', token)
                localStorage.setItem('country', data.country)
                localStorage.setItem('symbol', data.currency === 'INR' ? '₹' : data.currency === 'EUR' ? '€' : '$')
                localStorage.setItem('rate', data.currency === 'INR' ? 1 : data.currency === 'EUR' ? 87 : 83) 

                window.location.replace('/#/')
                window.location.reload()
            }).catch(error => {
                setLoader(false)
                setIsError(true)
                setErrorText(error.response.data.message)

                const timerId = setTimeout(() => {
                    setIsError(false)
                    setErrorText('')
                }, 1500);

                return () => clearTimeout(timerId);
            })
    }

    return (
        <div className='login'>
            {isError ? <Error errorMsg={errorText} /> : <></>}
            {loader ? <Loading /> : <></>}

            {popup ? <div className='popup--screen'>
                <div className="popup--canceller" onClick={() => setPopup(!popup)}></div>
                <div className="main">
                    <div className="popup--top">
                        <div className="left">Select Country</div>
                        <div className="right">
                            <div className="icon" onClick={() => setPopup(!popup)}>
                                <RxCross2 />
                            </div>
                        </div>
                    </div>

                    <div className="popup--body">
                        {isdList.map((item, index) => {
                            return (
                                <div className="col" key={index} onClick={() => { setIsd(item.ISD_code); setPopup(!popup) }}>
                                    <div className="left">
                                        <div className="code">{item.ISD_code}</div>
                                        <div className="country">{item.country}</div>
                                    </div>

                                    {item.ISD_code === isd ? <div className="right">
                                        <IoCheckmarkDoneSharp />
                                    </div> : <></>}
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div> : <></>}

            <center>
                <div className="logo">
                    <img src={logo} height={'120'} alt="" />
                </div>
            </center>

            <div className="form">
                <center>
                    <div className="title">Sign In</div>
                    <div className="border"></div>
                </center>

                <div className="input type2">
                    <div className="left" onClick={() => setPopup(!popup)}>
                        <div className="code">
                            {isd}
                        </div>
                        <div className="icon">
                            <IoMdArrowDropdown />
                        </div>
                    </div>
                    <input type="tel" maxLength={10} value={phone} onChange={(e) => setPhone(e.target.value)} placeholder='Phone no' />
                </div>
                <div className="input">
                    <div className="left">
                        <div className="icon">
                            <IoKeyOutline />
                        </div>
                    </div>
                    <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
                </div>

                <div className="txt2">Forgot Password?</div>

                <center>
                    <button className="login--btn" onClick={loginAcc}>Login</button>
                    <div className="text">
                        <div className="txt">Don't have an account?</div><div className='link' onClick={() => window.location.href = '/#/register'}>Register now</div>
                    </div>
                </center>
            </div>
        </div>
    )
}

export default Login